import axios from "axios"
import React, { useState, useEffect } from "react"
import { DLFAdministeredCategories } from "../../Utils/Entities"

const Categories = props => {
  const accessToken = props?.token
  let myCat: string[] = []
  //   console.log(props?.contact)
  if (
    props?.contact?.dLFAdministeredCategories !== null &&
    props?.contact?.dLFAdministeredCategories !== undefined
  ) {
    myCat = props?.contact?.dLFAdministeredCategories.split(",")
  }
  const [allCat, setAll] = useState<DLFAdministeredCategories[]>([])
  useEffect(() => {
    const getInt = async () => {
      axios
        .get(`${process.env.GATSBY_API_URL}/Stringmaps/all/DLFAdm`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + (await accessToken),
          },
        })
        .then(response => {
          //   response.data?.forEach(r => {
          //     if (myCat.indexOf(r.id) > -1) {
          //       cat.push(r.label)
          //     }
          //   })
          //   cat.sort()
          setAll(response.data)
          //   setCat(response.data)
        })
        .catch(e => {
          console.log(e)
        })
    }
    getInt()
  }, [])

  allCat.sort(function (a, b) {
    if (a?.label < b?.label) {
      return -1
    }
    if (a?.label > b?.label) {
      return 1
    }

    return 0
  })
  return (
    <div className="mt-10">
      <h3 className="text-logo-700 font-medium text-lg ">
        Din forbindelse med DLF:
      </h3>

      <ul className="list-disc list-inside marker:text-logo-700 ">
        {allCat.map(c => {
          if (myCat.indexOf(c.id) > -1)
            return <li className="text-md">{c.label}</li>
        })}
      </ul>
    </div>
  )
}

export default Categories
