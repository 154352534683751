import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Options from "../Options/Options"
import { navigate } from "gatsby"
import axios from "axios"
import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { loginRequest } from "../Security/authConfig"
import {
  MsalAuthenticationTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react"
import { ErrorComponent } from "../../Utils/ErrorComponent"
import { Loading } from "../../Utils/Loading"
import { authToken } from "../Security/authToken"
import { Contact } from "../../Utils/Entities"

function MyPage() {
  const { instance, accounts, inProgress } = useMsal()
  const [compName, setCompName] = useState<string>()
  const accessToken = authToken(accounts, inProgress, instance)

  // const [accessToken, setAccessToken] = useState<Promise<string>>()
  const [isFulfilled, setFulfilled] = useState<boolean>(false)
  const [token, setToken] = useState<string>()
  const isAuthenticated = useIsAuthenticated()

  // // type Contact = {
  // //   firstName
  // // }

  const [contact, setContact] = useState<Contact>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    // setAccessToken(authToken(accounts, inProgress, instance))
    const getContact = async () => {
      // accessToken?.then(async () => {
      if (isAuthenticated) {
        setFulfilled(true)

        await axios
          .get(`${process.env.GATSBY_API_URL}/Contacts/me`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + (await accessToken),
            },
          })
          .then(response => {
            setContact(response.data)
          })
          .catch(error => {})
      }
    }

    getContact()
  }, [isAuthenticated])

  if (contact?.accountId != undefined) {
    // const accessToken = authToken(accounts, inProgress, instance)
    const getCompanyName = async () => {
      await axios
        .get(`${process.env.GATSBY_API_URL}/Accounts/${contact?.accountId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + (await accessToken),
          },
        })
        .then(response => {
          setCompName(response.data.companyName)
        })
        .catch(error => {
          // console.log(error)
        })
    }

    getCompanyName()
  }

  var nyhetsbrev = ".."
  if (contact?.newsLetter) {
    nyhetsbrev = "Ja"
  } else {
    nyhetsbrev = "Nei"
  }

  return (
    <>
      {contact !== undefined ? (
        <div className="m-20">
          <h2 className="leading-6 flex justify-center text-logo-700">
            Hallo, {contact?.firstName}!
          </h2>
          <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-5">
            <div className="lg:col-start-1 lg:col-span-2">
              {contact !== undefined ? (
                <Options props={contact} />
              ) : (
                <div></div>
              )}
              <div className="mt-10">
                <h3 className="text-logo-700">
                  Hvorfor ønsker vi å vite dine interesser?
                </h3>
                <p className="text-sm">
                  Vi har av og til behov for å sende ut ekstra informasjon om
                  disse temaene, i tillegg til i nyhetsbrev. {/* <br> */}
                  Ønsker du å motta dette må du huke av for hvilke temaer som er
                  interessant for deg. {/* </br> */}
                  Du kan når som helst legge til eller fjerne dine interesser
                  ved å huke av og oppdatere.
                </p>
              </div>
            </div>

            <div className="lg:col-start-3 lg:col-span-3">
              <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6 ">
                <div className="mt-4 max-w-md mx-auto">
                  <legend className="flex justify-center py-4 text-lg font-medium text-logo-700">
                    Personlige opplysninger
                  </legend>
                </div>

                <dl>
                  <div className="hidden">
                    <dt>Id</dt>
                    <dd>{contact?.id}</dd>
                  </div>
                  <div className="bg-white py-2 sm:grid sm:grid-cols-3 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Navn</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-right">
                      {contact?.firstName} {contact?.lastName}
                    </dd>
                  </div>
                  <div className="bg-white py-2 sm:grid sm:grid-cols-3 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Epost</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-right">
                      {contact?.emailAddress}
                    </dd>
                  </div>

                  <div className="bg-white py-2 sm:grid sm:grid-cols-3 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Bedrift
                    </dt>
                    {compName ? (
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-right">
                        {compName}
                      </dd>
                    ) : (
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-right">
                        ---
                      </dd>
                    )}
                  </div>
                  <div className="bg-white py-2 sm:grid sm:grid-cols-3 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Jobtittel
                    </dt>
                    {contact?.jobTitle ? (
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-right">
                        {contact?.jobTitle}
                      </dd>
                    ) : (
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-right">
                        ---
                      </dd>
                    )}
                  </div>

                  <div className="bg-white py-2 sm:grid sm:grid-cols-3 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Telefon, arbeid
                    </dt>
                    {contact?.businessPhone ? (
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-right">
                        {contact?.businessPhone}
                      </dd>
                    ) : (
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-right">
                        ---
                      </dd>
                    )}
                  </div>
                  <div className="bg-white py-2 sm:grid sm:grid-cols-3 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Telefon, privat
                    </dt>
                    {contact?.mobilePhone ? (
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-right">
                        {contact?.mobilePhone}
                      </dd>
                    ) : (
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-right">
                        ---
                      </dd>
                    )}
                  </div>

                  <div className="bg-white py-2 sm:grid sm:grid-cols-3 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Meldt på nyhetsbrev:
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-right">
                      {nyhetsbrev}
                    </dd>
                  </div>

                  <div className="sm: col-span-2 flex justify-center mt-2">
                    <Link to={`/endreBruker/`} state={{ contact: contact }}>
                      <button
                        type="submit"
                        onClick={() => navigate("/endreBruker/")}
                        className="ml-1 inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-logo-600 hover:bg-logo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-logo-500"
                      >
                        Endre
                      </button>
                    </Link>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </>
  )
}

const MyPageContent = () => {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <MyPage />
    </MsalAuthenticationTemplate>
  )
}

export default MyPageContent
