import { navigate } from "gatsby-link"
import React, { useState, useEffect } from "react"
import axios from "axios"
import { useForm, SubmitHandler } from "react-hook-form"
import { string } from "prop-types"
import { authToken } from "../Security/authToken"
import { useMsal } from "@azure/msal-react"
import { SelfAdministeredCategories } from "../../Utils/Entities"

const checkInterrests = (checked, interrest) => {
  if (checked == "true") {
    // console.log(interrest)
  }
  return interrest
}

type FormValues = { selfAdministeredCategories: string }

const Options = props => {
  // const { instance, accounts, inProgress } = useMsal()
  // const accessToken = authToken(accounts, inProgress, instance)
  // console.log(props.props)
  const accessToken = props?.token
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>()

  const [cat, setCat] = useState<SelfAdministeredCategories[]>([])
  const [loading, setLoading] = useState(false)
  const [interrests, setInterrests] = useState("")

  // Get all interrests
  useEffect(() => {
    setLoading(true)
    const getInt = async () => {
      axios
        .get(`${process.env.GATSBY_API_URL}/Stringmaps/all/selfAdm`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + (await accessToken),
          },
        })
        .then(response => {
          setCat(response.data)
        })
        .catch(e => {
          // console.log(e)
        })
        .finally(() => {
          setLoading(false)
        })
    }
    getInt()
    const check = async () => {
      await cat.forEach(c => {
        // intr.forEach(i => {
        //   i.indexOf(c.id)
        // })
        if (intr.indexOf(c.id) > -1) {
          c.confirmed = true
        } else {
          c.confirmed = false
        }
      })
    }
    check()
  }, [])

  let intr: string[] = []
  if (
    props.props?.selfAdministeredCategories !== null &&
    props.props?.selfAdministeredCategories !== undefined
  ) {
    intr = props.props.selfAdministeredCategories.split(",")
  }
  const id = props?.props?.id

  const [showSuccess, setSuccessState] = useState(false)
  const [showFailed, setFailedState] = useState(false)

  const updateInterrests = async (data, event) => {
    setSuccessState(false)
    setFailedState(false)
    // check if some interests are checked
    if (intArray.length > 0) {
      const input = {
        id: props.props.id,
        selfAdministeredCategories: intArray.toString(),
      }

      await axios
        .patch(`${process.env.GATSBY_API_URL}/Contacts/edit`, input, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + (await accessToken),
          },
        })
        .then(response => {
          setSuccessState(true)
          setFailedState(false)
        })
        .catch(error => {
          setFailedState(true)
          setSuccessState(false)
        })
      event.preventDefault()
    }
    // update interest field to empty if everything is unchecked
    else if (intArray.length === 0) {
      await axios
        .delete(`${process.env.GATSBY_API_URL}/Contacts/clear?id=${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + (await accessToken),
          },
        })
        .then(response => {
          setSuccessState(true)
          setFailedState(false)
        })
        .catch(error => {
          setFailedState(true)
          setSuccessState(false)
        })
      event.preventDefault()
    }
  }

  let intArray = intr

  const handleCheckboxChanged = event => {
    // for (var c in cat) {
    //   if (cat[c].id == category.id) {
    //     cat[c].confirmed = value
    //   }
    // }

    if (event.target.checked && !intArray.includes(event.target.id)) {
      intArray.push(event.target.id)
      // event.target.confirmed = true
    } else if (!event.target.checked) {
      var index = intArray.indexOf(event.target.value)
      // event.target.confirmed = false
      if (index !== -1) {
        intArray.splice(index, 1)
      }
    }
    intArray.sort()
  }

  cat.sort(function (a, b) {
    if (a?.label < b?.label) {
      return -1
    }
    if (a?.label > b?.label) {
      return 1
    }

    return 0
  })

  return (
    <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
      <form method="PATCH" onSubmit={handleSubmit(updateInterrests)}>
        <div className="mt-4 px-4 sm:px-6 max-w-md mx-auto">
          <legend className="flex justify-center py-4 text-lg font-medium text-logo-700">
            Mine interesser
          </legend>
          {loading ? (
            <p className="hidden">Data is loading...</p>
          ) : (
            cat.map(c => {
              return (
                <div key={c.id} className="relative flex items-start py-2">
                  <div className="min-w-0 flex-1 text-sm">
                    <label
                      htmlFor={c.id}
                      className="font-medium text-gray-700 select-none"
                    >
                      {c.label}
                    </label>
                  </div>
                  <div className="ml-1 h-5">
                    <input
                      id={c.id}
                      name={c.label}
                      value={c.id}
                      type="checkbox"
                      // marked as checked for previously chosen interrests
                      defaultChecked={intr.indexOf(c.id) > -1}
                      // checked={c.confirmed}
                      // adds interrest to array if checked, delete if unchecked
                      onChange={handleCheckboxChanged}
                      // onClick={() => !c.confirmed}
                      className="focus:ring-logo-500 h-4 w-4 text-logo-600 border-logo-700 border-2 rounded"
                    />
                  </div>
                </div>
              )
            })
          )}
          <div className="flex justify-center py-4">
            <button
              type="submit"
              className="ml-1 inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-logo-600 hover:bg-logo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-logo-500"
            >
              Oppdater
            </button>
          </div>
        </div>
      </form>

      <div className="mb-2 flex justify-center">
        {showSuccess ? (
          <p className="text-green-500">Interesser oppdatert!</p>
        ) : (
          <div></div>
        )}
        {showFailed ? (
          <p className="text-red-500">Feil ved oppdatering av interesser.</p>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  )
}

export default Options
