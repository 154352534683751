import React, { useState, useEffect, useCallback } from "react"
// import NewsletterSignup from "../components/Forms/NewsletterSignup"
// import MainLayout from "../components/Layout/MainLayout"
import MyPageLayout from "../components/Layout/MyPageLayout"
import MyPageContent from "../components/MyPage/MyPageContent"
import { Link } from "gatsby"
import Options from "../components/Options/Options"
import { navigate } from "gatsby"
import axios from "axios"
import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { loginRequest } from "../components/Security/authConfig"
import {
  MsalAuthenticationTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react"
import { ErrorComponent } from "../Utils/ErrorComponent"
import { Loading } from "../Utils/Loading"
import { authToken } from "../components/Security/authToken"
import Categories from "../components/Options/Categories"

const MyPage = () => {
  const { instance, accounts, inProgress } = useMsal()
  const [compName, setCompName] = useState()
  const accessToken = authToken(accounts, inProgress, instance)

  const [isFulfilled, setFulfilled] = useState(false)
  const [token, setToken] = useState()
  const isAuthenticated = useIsAuthenticated()

  const [contact, setContact] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showAdministrated, setShowAdministrated] = useState(false)

  const getContact = useCallback(async () => {
    if (isAuthenticated) {
      // console.log(await accessToken)

      axios
        .get(`${process.env.GATSBY_API_URL}/Contacts/me`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + (await accessToken),
          },
        })
        .then(response => {
          setContact(response.data)
          if (
            response.data?.dLFAdministeredCategories !== null &&
            response.data?.dLFAdministeredCategories !== undefined
          )
            setShowAdministrated(true)
        })
        .catch(error => {
          // console.log(error)
        })
    }
  }, [accessToken, isAuthenticated])

  useEffect(() => {
    // if (accessToken == null) return
    if (contact === null || contact === undefined) getContact()
  }, [isAuthenticated, getContact])

  if (contact?.accountId !== undefined) {
    // const accessToken = authToken(accounts, inProgress, instance)
    const getCompanyName = async () => {
      await axios
        .get(`${process.env.GATSBY_API_URL}/Accounts/${contact?.accountId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + (await accessToken),
          },
        })
        .then(response => {
          setCompName(response.data.companyName)
        })
        .catch(error => {
          // console.log(error)
        })
    }

    getCompanyName()
  }

  var nyhetsbrev = ".."
  if (contact?.newsLetter) {
    nyhetsbrev = "Ja"
  } else {
    nyhetsbrev = "Nei"
  }

  return (
    <>
      {contact !== null && contact !== undefined ? (
        <div className="mb-10">
          <div className="m-20">
            <h2 className="leading-6 font-medium text-lg flex justify-center text-logo-700">
              Hallo, {contact?.firstName}!
            </h2>
            <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-5">
              <div className="lg:col-start-1 lg:col-span-2">
                {contact !== null ? (
                  <Options props={contact} token={accessToken} />
                ) : (
                  <div></div>
                )}
                <div className="mt-10">
                  <h3 className="text-logo-700 font-medium text-lg">
                    Hvorfor ønsker vi å vite dine interesser?
                  </h3>
                  <p className="text-md">
                    Vi har av og til behov for å sende ut ekstra informasjon om
                    disse temaene, i tillegg til i nyhetsbrev. {/* <br> */}
                    Ønsker du å motta dette må du huke av for hvilke temaer som
                    er interessant for deg. {/* </br> */}
                    Du kan når som helst legge til eller fjerne dine interesser
                    ved å huke av og oppdatere.
                  </p>
                </div>
              </div>

              <div className="lg:col-start-3 lg:col-span-3">
                <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6 ">
                  <div className="mt-4 max-w-md mx-auto">
                    <legend className="flex justify-center py-4 text-lg font-medium text-logo-700">
                      Personlige opplysninger
                    </legend>
                  </div>

                  <dl>
                    <div className="hidden">
                      <dt>Id</dt>
                      <dd>{contact?.id}</dd>
                    </div>
                    <div className="bg-white py-2 sm:grid sm:grid-cols-3 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Navn
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-right">
                        {contact?.firstName} {contact?.lastName}
                      </dd>
                    </div>
                    <div className="bg-white py-2 sm:grid sm:grid-cols-3 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Epost
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-right">
                        {contact?.emailAddress}
                      </dd>
                    </div>

                    <div className="bg-white py-2 sm:grid sm:grid-cols-3 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Bedrift
                      </dt>
                      {compName ? (
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-right">
                          {compName}
                        </dd>
                      ) : (
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-right">
                          ---
                        </dd>
                      )}
                    </div>
                    <div className="bg-white py-2 sm:grid sm:grid-cols-3 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Tittel
                      </dt>
                      {contact?.jobTitle ? (
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-right">
                          {contact?.jobTitle}
                        </dd>
                      ) : (
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-right">
                          ---
                        </dd>
                      )}
                    </div>

                    <div className="bg-white py-2 sm:grid sm:grid-cols-3 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Telefon, arbeid
                      </dt>
                      {contact?.businessPhone ? (
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-right">
                          {contact?.businessPhone}
                        </dd>
                      ) : (
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-right">
                          ---
                        </dd>
                      )}
                    </div>
                    <div className="bg-white py-2 sm:grid sm:grid-cols-3 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Mobil
                      </dt>
                      {contact?.mobilePhone ? (
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-right">
                          {contact?.mobilePhone}
                        </dd>
                      ) : (
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-right">
                          ---
                        </dd>
                      )}
                    </div>

                    <div className="bg-white py-2 sm:grid sm:grid-cols-3 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Meldt på nyhetsbrev:
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-right">
                        {nyhetsbrev}
                      </dd>
                    </div>

                    <div className="sm: col-span-2 flex justify-center mt-2">
                      <Link to={`/endreBruker/`} state={{ contact: contact }}>
                        <button
                          type="submit"
                          onClick={() => navigate("/endreBruker/")}
                          className="ml-1 inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-logo-600 hover:bg-logo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-logo-500"
                        >
                          Endre
                        </button>
                      </Link>
                    </div>
                  </dl>
                </div>
                <div className="flex justify-center">
                  {contact !== null && showAdministrated ? (
                    <Categories contact={contact} token={accessToken} />
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <h3 className=" text-center text-link-500 font-sm italic text-medium">
            Kontakt
            <a
              href="mailto: firmapost@dlf.no"
              className="font-bold text-link-500 hover:text-link-400"
            >
              {" "}
              DLF administrasjonen.
            </a>
          </h3> */}
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </>
  )
}

const IndexPage = ({ data }) => {
  // return (
  // <MainLayout props="DLF - Nyhetsbrev">
  //   <NewsletterSignup />
  // </MainLayout>
  //   <MyPageLayout props="DLF - Min Side">
  //     <MyPageContent />
  //   </MyPageLayout>
  // )
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MyPageLayout props="DLF - Min Side">
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={ErrorComponent}
        loadingComponent={Loading}
      >
        <MyPage />
      </MsalAuthenticationTemplate>
    </MyPageLayout>
  )
}

const auth = function (data) {
  // console.log(data)
}

export default IndexPage
